<template>
  <div class="google-ads-account pt-10">
    <!--    <p class="font-family-raleway-medium">Ads Accounts</p>-->
    <v-card class="my-campaigns-card" style="position: relative">
      <covered-loader v-if="isGettingAccounts" />
      <v-card-title class="ma-0" :class="getBreakPoint ? 'pa-4' : 'pa-0'">
        <h3 class="font-family-raleway-bold text-uppercase">
          {{ $t("my_accounts_page.title") }}
        </h3>
        <v-spacer></v-spacer>
        <span class="ma-0">
          <v-btn
            v-if="getBreakPoint"
            to="/welcome"
            class="button"
            height="50"
            style="border-radius: 50px"
          >
            <v-icon class="icon">mdi-plus</v-icon>
          </v-btn>
          <v-btn v-else class="button" height="48" to="/welcome">
            <img :src="plusIcon" width="16" height="16" />
            <h5 class="ml-2 font-family-raleway-bold">
              {{ $t("my_accounts_page.add_logins_button") }}
            </h5>
          </v-btn>
        </span>
      </v-card-title>
      <div class="d-flex space-between mb-2 mt-5 align-center">
        <v-spacer :class="getBreakPoint ? 'd-none' : ' '"></v-spacer>
      </div>
      <NestedDataTable
        @showModal="showModalToLink"
        :adsAccounts="accounts"
        :isGettingAccounts="isGettingAccounts"
      />
      <h4
        class="text-center font-family-raleway-light"
        v-if="googleAdsAccounts.length === 0 && !isGettingAccounts"
      >
        <span>No accounts</span>
      </h4>
      <v-divider class="mb-6" />
      <pagination
        class="pl-2"
        v-if="googleAdsAccounts.length > 8 && !isGettingAccounts"
        :currentPage="currentPage"
        :totalPage="totalPage"
        @handleUpdateCampaignList="handleUpdateCampaignList"
        @prePage="prePage"
        @nextPage="nextPage"
        @changePage="changePage($event)"
      />
      <my-accounts-mobile-table :adsAccounts="accounts" class="showMobile" />
    </v-card>
    <!--    <GoogleAddsAccountsCard />-->
    <ModalToLink
      :dialog="showConfirmModal"
      @cancelModal="showConfirmModal = false"
      :is-delete="isLinking"
      @agree="linkToAccount"
    />
  </div>
</template>
<script>
import sortIcons from "../../assets/icon/sort-icons.svg";
import plusIcon from "../../assets/icon/plus-icon.svg";
import searchIcon from "../../assets/icon/search.svg";
import linkIcon from "../../assets/icon/link.svg";
import mccLinkIcon from "../../assets/icon/mcc-link.svg";
import imgDown from "@/assets/img/down.svg";
import imgPrint from "@/assets/img/print.svg";
import imgEdit from "@/assets/img/edit.svg";
import { accountService } from "@/services/account";
import { authService } from "@/services/auth";

export default {
  name: "GoogleAddsAccounts",
  components: {
    CoveredLoader: () => import("@/components/CoveredLoader"),
    ModalToLink: () => import("@/components/dialogs/ModalToLink"),
    NestedDataTable: () => import("@/components/Account/NestedDataTable"),
    MyAccountsMobileTable: () => import("../../components/Account/MyAccountsMobileTable.vue"),
    Pagination: () => import("@/components/Pagination"),
  },
  data: () => ({
    sortIcons,
    imgDown,
    imgPrint,
    imgEdit,
    plusIcon,
    searchIcon,
    mccLinkIcon,
    linkIcon,
    selectedAccountId: "",
    accountId: "",
    showConfirmModal: false,
    isGettingAccounts: false,
    isLinking: false,
    headers: [
      { text: "Number", value: "number" },
      { text: "Account name", value: "name" },
      { text: "Status" },
    ],
    accounts: [],
    googleAdsAccounts: [],
    currentPage: 1,
    limitPage: 8,
  }),
  async created() {
    this.isGettingAccounts = true;
    await this.getAccounts();
    try {
      const { data } = await authService.getUserInfo();
      if (data?.googleAdsAccounts) {
        this.googleAdsAccounts = [
          ...this.googleAdsAccounts,
          ...data?.googleAdsAccounts.map((item) => {
            return {
              ...item,
              linkedItem: true,
            };
          }),
        ];
        this.isGettingAccounts = false;
        this.currentPage = 1;
        this.accounts = this.googleAdsAccounts.slice(0, this.limitPage);
      } else {
        this.accounts = [...this.googleAdsAccounts];
      }
      this.isGettingAccounts = false;
    } catch (e) {
      const data = e?.response;
      this.$notify({
        group: "foo",
        type: "error",
        title: data?.statusText ? data?.statusText : "Something went wrong!",
      });
    }
    this.isGettingAccounts = false;
  },
  computed: {
    getBreakPoint() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    totalPage: function () {
      return Math.ceil(this.googleAdsAccounts.length / this.limitPage);
    },
  },
  methods: {
    async getAccounts() {
      const userId = localStorage.getItem("userId");
      const res = await accountService.getByUserId(userId);
      this.googleAdsAccounts = [
        ...res.data.map((item) => {
          return {
            id: item?.id,
            name: item?.accountName,
            resourceName: item?.customerId.toString(),
            linked: false,
          };
        }),
      ];
    },
    numberWithSpaces(val) {
      let phone = val.replace(/(\d{3})(\d{3})(\d{4})/, "$1 $2 $3");
      return phone.replaceAll(" ", "-");
    },
    handleClickAccount(val) {
      localStorage.setItem("customerId", val.resourceName);
      localStorage.setItem("accountId", val.id);
      this.accountId = val.resourceName;
    },
    async linkToAccount() {
      const customerId = parseInt(this.selectedAccountId);
      this.isLinking = true;
      try {
        await accountService.linkAccount(customerId);
        this.$notify({
          group: "foo",
          type: "success",
          title: "Successfully linked!",
        });
        await this.getAccounts();
        this.showConfirmModal = false;
      } catch (e) {
        const { status, statusText } = e.response;
        this.$notify({
          group: "foo",
          type: "error",
          title: status === 500 ? "Something went wrong!" : statusText,
        });
      }
      this.isLinking = false;
      this.showConfirmModal = false;
    },
    showModalToLink(id) {
      this.selectedAccountId = id;
      this.showConfirmModal = true;
    },
    handleUpdateCampaignList(val) {
      let index = (val - 1) * this.limitPage;
      this.accounts = this.googleAdsAccounts.slice(
        index,
        index + this.limitPage
      );
    },
    prePage() {
      if (this.currentPage > 1) this.currentPage -= 1;
      this.handleUpdateCampaignList(this.currentPage);
    },
    nextPage() {
      if (this.currentPage < this.totalPage) this.currentPage += 1;
      this.handleUpdateCampaignList(this.currentPage);
    },
    changePage(page) {
      this.currentPage = page;
      this.handleUpdateCampaignList(this.currentPage);
    },
  },
};
</script>

<style lang="scss" scoped>
.showMobile {
  display: none;
  @media screen and (max-width: 800px) {
    display: block;
  }
}

@media screen and (max-width: 600px) {
  .button {
    border-radius: 50px !important;
    padding: 0 !important;
    min-width: 48px !important;
    height: 48px !important;
  }
}

.my-campaigns-card {
  padding: 40px;
  box-shadow: none !important;
  border-radius: 12px !important;
  @media screen and (max-width: 600px) {
    padding: 0 !important;
  }
  @media screen and (max-width: 450px) {
    padding: 20px 10px;
  }

  .table {
    box-shadow: none !important;
    @media screen and (max-width: 800px) {
      display: none;
    }

    thead {
      tr td {
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      }
    }

    tbody {
      tr td {
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      }
    }
  }

  .chip {
    height: 32px;
    border-radius: 10px;
    text-transform: uppercase;
  }
}

.tbody {
  p {
    color: gray !important;
    font-size: 14px !important;
  }

  span {
    color: #0057ff !important;
  }
}

.down-btn {
  background-color: #f5f9fc !important;
  border-radius: 12px !important;
  box-shadow: none;
}

.table-row {
  height: 70px !important;
  cursor: pointer;
  &:hover {
    background-color: #f6f9fc !important;
  }

  td {
    border: none !important;
  }
}
.not-linked-style {
  background-color: #f2f2f7 !important;
  box-shadow: none !important;
  color: #828282 !important;
  font-weight: bold;
  height: 32px !important;
  font-size: 12px !important;
  border-radius: 10px;
}
.mcc-linked-style {
  background-color: #dae3f8 !important;
  box-shadow: none !important;
  color: #0057ff !important;
  font-weight: bold;
  height: 32px !important;
  font-size: 12px !important;
  border-radius: 10px;
}
</style>
